import React, { createContext, useState,useEffect,useContext } from 'react';

const LocationContext = createContext();

export const useLocationData = () => { // ここを変更
  return useContext(LocationContext);
};

export const LocationProvider = ({ children }) => {
  const [locationData, setLocationData] = useState({ latitude: null, longitude: null });
  // // 位置情報を取得する関数
  // const fetchLocation = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(position => {
  //       setLocationData({
  //         latitude: position.coords.latitude,
  //         longitude: position.coords.longitude,
  //       });
  //     }, error => {
  //       console.error("位置情報の取得に失敗しました:", error);
  //     });
  //   } else {
  //     console.error("このブラウザは位置情報の取得に対応していません。");
  //   }
  // };
  // // コンポーネントがマウントされた時に位置情報を取得
  // useEffect(() => {
  //   fetchLocation();
  // }, []);

  return (
    <LocationContext.Provider value={{ locationData, setLocationData }}>
      {children}
    </LocationContext.Provider>
  );
};
