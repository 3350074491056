// custom typefaces
// import "@fontsource/montserrat/variable.css"
// import "@fontsource/merriweather"
// import '@fontsource/zen-kaku-gothic-new/400.css';
// import '@fontsource/zen-kaku-gothic-new/500.css';
// import '@fontsource/zen-kaku-gothic-new/700.css';
// normalize CSS across browsers
//import "./src/normalize.css"
// custom CSS styles
//import "./src/style.css"
// Highlighting for code blocks
//import "prismjs/themes/prism.css"
import "./src/scss/style.scss"



import React, { useState } from "react"
import SearchContext from './src/contexts/SearchContext';
import { LocationProvider } from './src/contexts/LocationContext'; 

export const wrapRootElement = ({ element }) => {
  const RootElement = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [tag, setTag] = useState('');
    const [tag2, setTag2] = useState('');
    const [totalBooks, setTotalBooks] = useState(0); 
    const [dialogOpen, setDialogOpen] = useState(false);
    const [detailTag, setDetailTag] = useState('');
    const [detailAuthor, setDetailAuthor] = useState('');
    const [detailclose, setDetailClose] = useState(false);
    const [isInViewPort, setIsInViewPort] = useState(false);
    const [searchFooterOpen, setSearchFooterOpen] = useState(false);

    return (
      <LocationProvider>
      <SearchContext.Provider value={{ searchQuery, setSearchQuery, tag, setTag,tag2, setTag2, totalBooks, setTotalBooks, dialogOpen, setDialogOpen,detailTag,setDetailTag,detailclose,setDetailClose,detailAuthor,setDetailAuthor,isInViewPort,setIsInViewPort,searchFooterOpen,setSearchFooterOpen}}>
        {element}
      </SearchContext.Provider>
      </LocationProvider>
    );
  };

  return <RootElement />;
}

export const onInitialClientRender = () => {
  window.asyncLoadCss = function(styleFile) {
    window.addEventListener("load", function() {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = styleFile;
      link.media = "all";
      document.head.appendChild(link);
    });
  };

  window.asyncLoadCss("/fonts/ZenKakuGothicNew.css");
};



