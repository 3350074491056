import React from 'react';

const SearchContext = React.createContext({
  searchQuery: '',
  setSearchQuery: () => {},
  tag: '',
  setTag: () => {},
  tag2: '',
  setTag2: () => {},
  totalBooks: 0,
  setTotalBooks: () => {},
  dialogOpen: false,
  setDialogOpen: () => {},
  detailTag: '', // 変更: selectedTagを追加
  setDetailTag: () => {}, // 変更: setSelectedTagを追加
  detailAuthor: '', // 著者の詳細
  setDetailAuthor: () => {}, // 著者の詳細を設定
  detailclose:false,
  setDetailClose:() => {},
  isInViewPort: false,
  setIsInViewPort: () => {},
  searchFooterOpen: false,
  setSearchFooterOpen: () => {},
});

export default SearchContext;